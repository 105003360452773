@charset 'UTF-8';

body {
  @include PC {
    overflow: auto!important;
  }
}



.header {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1000;
  // max-width: 1220px;
  max-width: 100%;
  width: calc(100% - 60px);
  margin: 0 auto;
  background: #fff;
  @include SP {
    height: 60px;
    width: calc(100% - 30px);
  }
  .inHeader {
    position: relative;
    z-index: 2000;
    background: #fff;
    padding: 0;
  }
  #logo {
    position: absolute;
    top: 15px;
    left: 20px;
    @include max-screen(1000px) {
      width: 20%;
      top: 22px;
      left: 15px;
    }
    @include SP {
      max-width: 206px;
      z-index: 1999;
      width: 100%;
      top: 15px;
      left: 15px;
    }
  }

}

.layerMenu {
  text-align: right;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  .gNavi {
    display: inline-block;
    vertical-align: middle;
    width: 77%;
    text-align: right;
    > li {
      display: inline-block;
      vertical-align: top;
      width: auto;
      margin-right: 2.7%;
      letter-spacing: 1px;
      .l-cn & {
        margin-right: 3.9%;
      }
      @include max-screen(900px) {
        margin-right: 1.5%;
      }
      > a {
        display: block;
        position: relative;
        @include ffYM;
        color: #15181F;
        letter-spacing: 0.9px;
        padding: 23px 0;
        @include HOVER {
          &:hover {
            opacity: 1;
            color: #1B307A;
            &:after {
              width: 100%;
            }
          }
        }
        .l-en & {
          @include ffYMon;
          @include PC {
            @include font(15,20,25);
            padding: 0;
            line-height: 71px;
          }
        }
        .l-cn & {
          @include ffSerifSC;
          letter-spacing: 0.1em;
        }
        &:before {
          content: '';
          position: absolute;
          right: 2px;
          top: 50%;
          width: 8px;
          height: 8px;
          margin-top: -6px;
          border-left: 1px solid #1B307A;
          border-top: 1px solid #1B307A;
          @include transform_c(rotate(-135deg));
          @include add_prefix(transition, 0.3s ease all);
          display: none;
          .l-en & {
            @include PC {
              width: 7px;
              height: 7px;
            }
          }
        }
        &:after {
          position: absolute;
          content: '';
          width: 0;
          transition: all 0.3s;
          height: 2px;
          left: 0;
          bottom: 0;
          background-image: linear-gradient(45deg, #1D53C3, #ABC5E8);
        }
      }
      &.hasSub {
        .l-cn & {
          margin-right: 2.5%;
        }
        a {
          padding-right: 20px;
          @include PC {
            .l-en & {
              padding-right: 15px;
            }
          }
          &:before {
            display: block;
          }
          @include HOVER {
            &:hover {
              &:after {
                height: 3px;
              }
            }
          }
        }
        .plus { display: none; }
      }
      &.active {
        a {
          &:before {
            margin-top: -3px;
            @include transform_c(rotate(45deg));
          }
        }
      }
    }
    .navSub {
      position: absolute;
      left: 50%;
      top: 70px;
      width: 100%;
      @include transform_c(translateX(-50%));
      display: none;
      padding: 40px 20px 20px;
      background: #F4F4F4;
      ul {
        max-width: 1000px;
        margin: 0 auto;
        li {
          text-align: left;
          float: left;
          width: 23.5%;
          margin-right: 2%;
          margin-bottom: 20px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .l-cn & {
            @include PC {
              &:nth-child(4n) {
                margin-right: 2%;
              }
              &:first-child {
                margin-left: 11.75%;
              }
              &.sub08 {
                margin-right: 0;
              }
            }
          }
          a {
            display: block;
            padding: 10px 30px 6px 20px;
            position: relative;
            @include ffYM;
            @include font-size(16);
            background: #fff;
            color: #1B307A;
            line-height: 26px;
            position: relative;
            @include ipad {
              @include font-size(14);
            }
            .l-en & {
              @include ffYMon;
            }
            .l-cn & {
              @include ffSerifSC;
              font-weight: 500;
            }
            em {
              font-style: normal;
              position: relative;
              z-index: 2;
            }
            @include max-screen(1000px) {
              @include font-size(12);
              padding-left: 10px;
            }
            @include HOVER {
              &:hover {
                opacity: 1;
                color: #fff;
                &:before {
                  border-left: 1px solid #fff;
                  border-top: 1px solid #fff;
                }
                &:after {
                  opacity: 1;
                }
              }
            }

            &:before {
              content: "";
              position: absolute;
              z-index: 2;
              right: 20px;
              top: 50%;
              margin-top: -4px;
              width: 8px;
              height: 8px;
              border-left: 1px solid #1B307A;
              border-top: 1px solid #1B307A;
              -moz-transform: rotate(135deg);
              -webkit-transform: rotate(135deg);
              -ms-transform: rotate(135deg);
              transform: rotate(135deg);
            }
            &:after {
              z-index: 1;
              position: absolute;
              content: '';
              background: linear-gradient(45deg, rgba(29,83,195,1) 0%, rgba(171,197,232,1) 100%);
              width: 100%;
              height: 100%!important;
              top: 0;
              left: 0;
              opacity: 0;
              transition: all 0.3s;
            }
          }
        }
        .l-en & {
          @include PC {
            // .sub01, .sub02, .sub03 {
            //   width: 17.33%;
            // }
            // .sub05 {
            //   width: 41%;
            // }
            // .sub06 {
            //   width: 31.3%;
            // }
            // .sub07 {
            //   width: 31.3%;
            // }
            .sub06 {
              margin-left: 11.75%;
            }
            .sub08 {
              // width: 32.3%;
              margin-right: 0;
            }
            .sub05 {
              a {
                padding-top: 9px;
                padding-bottom: 9px;
                line-height: 14px;
              }
            }
            a {
              padding: 10px 30px 10px 10px;
              @include font-size(13);
              // letter-spacing: 0.025em;
              letter-spacing: -0.4px;
            }
          }
          @include SP {
            a {
              @include font(13,15,0,500);
              padding: 8px 28px 8px 20px;
            }
          }
        }
      }
    }
  }
  .list-lang {
    display: inline-block;
    vertical-align: middle;
    max-width: 84px;
    width: 100%;
    text-align: center;
    padding: 0 10px;
    border-left: 1px solid #E6E6E6;
    @include SP {
      display: block;
      max-width: 100%;
      padding: 0;
      border-left: 0;
      @include font-size(0);
      margin-top: 15px;
      margin-bottom: 5px;
    }
    li {
      @include SP {
        display: inline-block;
        vertical-align: middle;
        width: 32.5%;
        margin-right: 1.25%;
        &:last-child {
          margin-right: 0;
        }
      }
      &.active-lang {
        a {
          color: #1B307A;
          @include SP {
            background: #F4F4F4;
            border: solid 1px #F4F4F4!important;
          }
        }
      }
      &:first-child {
        a {
          padding-top: 1px;

        }
      }
      &:last-child {
        a {
          border-bottom: 0;
        }
      }
      a {
        display: block;
        @include font-size(12);
        color: #B8BAC1;
        padding: 2px 0;
        border-bottom: 1px solid #E6E6E6;
        font-weight: 600;
        &.txt-ja {
          @include ffYG;
          font-weight: 500;
        }
        &.txt-cn {
          @include ffNotoSC;
          font-weight: 400;
        }
        &.txt-en {
          @include ffYMon;
          font-weight: 500;
          letter-spacing: 0.15em;
          white-space: nowrap;
        }
        @include SP {
          padding: 4px!important;
          border: solid 1px #E6E6E6!important;
          @include font-size(13);
        }
      }
    }
  }
  .contact-btn {
    display: none;
    vertical-align: middle;
    height: 71px;
    width: 110px;
    color: #fff;
    font-weight: 500;
    @include ffYMon;
    @include font-size(12);
    text-align: center;
    letter-spacing: 2px;
    background-image: linear-gradient(45deg, #1D53C3, #ABC5E8);
    @include HOVER {
      &:hover {
        background-image: linear-gradient(45deg, #ABC5E8, #1D53C3);
        opacity: 1;
      }
    }
    @include max-screen(900px) {
      width: 80px;
    }
    @include SP {
      width: 100%;
      @include font-size(13);
      height: 30px;
      line-height: 30px;
      background-image: linear-gradient(45deg, #1D53C3, #ABC5E8);
      margin-bottom: 4px;
    }
    span {
      position: relative;
      padding-top: 47px;
      display: block;
      @include SP {
        padding: 0 0 0 24px;
      }
      &:before {
        position: absolute;
        content: '';
        background: url($img_url + 'common/icon/ico_mail.svg') no-repeat;
        background-size: 100% 100%;
        width: 22px;
        height: 16px;
        top: 20px;
        left: 50%;
        margin-left: -11px;
        @include SP {
          width: 14px;
          height: 10px;
          top: 11px;
          left: 36%;
        }
      }
    }
  }

  .contact-btn-jp {
    display: inline-block;
    vertical-align: middle;
    height: 71px;
    width: 110px;
    color: #fff;
    font-weight: 500;
    
    
    text-align: center;
    letter-spacing: 2px;
    
    @include max-screen(900px) {
      width: 80px;
    }
    @include SP {
      display: block;
      @include font-size(13);
      height: 30px;
      line-height: 30px;
      background-image: linear-gradient(45deg, #1D53C3, #ABC5E8);
      margin-bottom: 4px;
        width: calc((100% - 5px)/2);
    }
    span {
      color: #fff;
      position: relative;
      display: block;
      background-image: linear-gradient(45deg, #1D53C3, #ABC5E8);
      display: flex;
        align-items: center;
        justify-content: center;
      @include PC{
        
      height: 35px;
      }
      @include HOVER {
      &:hover {
        background-image: linear-gradient(45deg, #ABC5E8, #1D53C3);
        opacity: 1;
      }
      @include SP {
       
        background-image: linear-gradient(45deg, #1D53C3, #ABC5E8);
      }
      &.contact{
       
        @include font-size(13);
        font-weight: 500;
        letter-spacing: .15em;
        @include ffYMon;
        @include PC{
          border-bottom: 1px solid rgba(255, 255, 255, .4);;
        }
        
      }
      &.recruit{
        @include font-size(13);
        font-weight: 500;
        letter-spacing: .10em;
       
        @include PC{
          height: 36px;
        }
      }
    }
    
    }
  }
  @include max-screen(767px) {
    position: fixed;
    right: -120%;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    display: block;
    text-align: left;
    background: #fff;
    padding: 60px 40px 65px;
    overflow-y: scroll;
    .noAddressBar & {
      padding-bottom: 20px;
    }
    .inLayer {
      position: relative;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .gNavi {
      display: block;
      width: 100%;
      text-align: left;
      > li {
        display: block;
        width: 100%;
        font-weight: bold;
        border-bottom: 1px solid #E6E6E6;
        margin: 0;
        > a {
          @include font-size(15);
          padding: 12px 0;
          font-weight: 500;
          .l-en & {
            letter-spacing: 0.025em;
          }
          &:before {
            display: none;
          }
        }
        &.hasSub {
          border-bottom: 0;
          > a {
            &:before {
              // @include transform_c(rotate(-135deg));
              display: none;
            }
          }
        }
        &.active {
          > a {
            &:before {
              @include transform_c(rotate(45deg));
            }
          }
        }
      }
      .navSub {
        position: static;
        left: auto;
        top: auto;
        width: 100%;
        padding: 0;
        @include transform_c(none);
        display: block;
        background: #fff;

        ul {
          li {
            float: none;
            width: 100%;
            padding: 0;
            margin: 0 0 5px;
            &:last-child {
              margin-bottom: 0;
            }
            a {
              background: #F4F4F4;
              @include font-size(14);
              padding: 2px 15px 2px 20px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}

.layerOn {
  overflow: hidden;
  position: relative;
  @include SP {
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(21, 24, 31, 0.6);
      top: 0;
      left: 0;
      z-index: 99;
    }
  }
  .hamberger {
    border-left: 0;
    padding-top: 20px;
  }
  .layerMenu {
    right: 0;
  }
}

.hamberger {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 60px;
  height: 60px;
  line-height: 1;
  font-size: 0;
  text-align: center;
  cursor: pointer;
  display: none;
  padding-top: 15px;
  border-left: 1px solid #E6E6E6;
  span {
    display: block;
    width: 26px;
    height: 1px;
    background: #15181F;
    margin: 0 auto 8px;
    @include add_prefix(transition, 0.3s ease all);
  }
  strong {
    @include font-size(10);
    @include ffYMon;
    font-weight: normal;
    display: block;
    margin-top: 12px;
    letter-spacing: 1px;
  }
  &.active {
    span {
      width: 26px;
      background: #B8BAC1;
    }
    strong {
      display: none;
    }
    .ham { @include transform_c(rotate(45deg) translateY(6px) translateX(7px)); }
    .ber { @include transform_c(rotate(-45deg)); }
    .ger { display: none; }
  }
  @include max-screen(767px) {
    display: block;
    z-index: 1999;
  }
}

.flex{
  display: flex;
  justify-content: space-between;
}
.l-cn,.l-en{
  .jp{
    display: none!important;
  }
  .contact-btn{
    display: inline-block;

    @include SP{
      display: block;

    }
  }
}
