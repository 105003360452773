@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */

.content-editor {
  max-width: 1000px;
  margin: 0 auto 62px;
  @include SP {
    margin-bottom: 40px;
  }
  h3 {
    @include ffYM;
    @include font-size(18);
    line-height: 36px;
    letter-spacing: 1.7px;
    padding-bottom: 10px;
    border-bottom: 1px solid #B8BAC1;
    margin-bottom: 40px;
    @include SP {
      @include font-size(17);
      line-height: 26px;
    }
  }
  h4 {
    @include ffYM;
    @include font-size(17);
    color: #1B307A;
    line-height: 32px;
    padding: 8px 20px;
    background: #F4F4F4;
    letter-spacing: 1.6px;
    margin-bottom: 30px;
    @include SP {
      @include font-size(16);
      line-height: 26px;
      padding: 8px 14px;
      margin-bottom: 22px;
    }
  }
  h5 {
    @include font-size(15);
    line-height: 32px;
    font-weight: bold;
    color: #1B307A;
    margin-bottom: 13px;
    letter-spacing: 1.5px;
    @include SP {
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
  p {
    @include font-size(15);
    line-height: 32px;
    letter-spacing: 0.7px;
    margin-bottom: 40px;
    span[style*="text-decoration: underline"] {
      padding-bottom: 2px;
      background-image: linear-gradient(to right, #E6E6E6, #E6E6E6);
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 100% 8px;
      text-decoration: none!important;
    }
    @include SP {
      line-height: 28px;
      margin-bottom: 32px;
    }
  }
  img {
    display: block;
    max-width: 100%;
    margin: 0 0 31px;
    @include SP {
      margin-bottom: 23px;
    }
  }
  a {
    @include font-size(15);
    font-weight: bold;
    color: #ABC5E8;
    text-decoration: underline;
    letter-spacing: 0.7px;
    @include SP {
      line-height: 28px;
    }
  }
  ul, ol {
    list-style-position: inside;
  }
  blockquote {
    display: block;
    padding: 20px 30px;
    border: solid 1px #E6E6E6;
    letter-spacing: 0.7px;
    line-height: 32px;
    p {
      margin-bottom: 0;
    }
    @include SP {
      line-height: 28px;
      padding: 23px 25px 23px 30px;
      letter-spacing: 0.5px;
    }
  }
}

.l-en {
  .content-editor {
    h3 {
      @include ffYMon;
      line-height: 26px;
      letter-spacing: 0.6px;
      padding-bottom: 18px;
      font-weight: 500;
      @include SP {
        letter-spacing: 0.4px;
        padding-bottom: 12px;
      }
    }
    h4 {
      @include ffYMon;
      line-height: 26px;
      font-weight: 500;
      padding: 12px 20px;
      letter-spacing: 0.4px;
      margin-bottom: 35px;
      @include SP {
        padding: 10px 15px;
        line-height: 22px;
        margin-bottom: 28px;
      }
    }
    h5 {
      @include ffYMon;
      line-height: 28px;
      font-weight: 500;
      letter-spacing: 0.6px;
      margin-bottom: 22px;
      @include SP {
        line-height: 24px;
      }
    }
    p {
      @include ffYMon;
      line-height: 28px;
      font-weight: 500;
      letter-spacing: 0.4px;
      @include SP {
        line-height: 22px;
      }
    }
    a {
      @include ffYMon;
      line-height: 28px;
      letter-spacing: 0.4px;
      @include SP {
        line-height: 22px;
      }
    }
    blockquote {
      @include ffYMon;
      padding: 24px 30px;
      @include SP {
        padding: 29px 30px;
      }
    }
  }
}

.l-cn {
  .content-editor {
    h3 {
      @include ffSerifSC;
      line-height: 36px;
      letter-spacing: 1.8px;
      padding-bottom: 13px;
      font-weight: 500;
      margin-bottom: 40px;
      @include SP {
        line-height: 26px;
        padding-bottom: 8px;
      }
    }
    h4 {
      @include ffSerifSC;
      line-height: 32px;
      font-weight: 500;
      padding: 12px 20px;
      letter-spacing: 1.7px;
      margin-bottom: 32px;
      @include SP {
        padding: 10px 15px;
        line-height: 26px;
        margin-bottom: 25px;
      }
    }
    h5 {
      @include ffNotoSC;
      line-height: 32px;
      font-weight: bold;
      letter-spacing: 1.5px;
      margin-bottom: 20px;
      @include SP {
        line-height: 24px;
        margin-bottom: 17px;
      }
    }
    p {
      @include ffNotoSC;
      line-height: 32px;
      letter-spacing: 0.7px;
      @include SP {
        line-height: 28px;
        margin-bottom: 31px;
      }
    }
    a {
      @include ffNotoSC;
      line-height: 32px;
      letter-spacing: 0.7px;
      @include SP {
        line-height: 28px;
      }
    }
    blockquote {
      @include ffYMon;
      padding: 24px 30px;
      p {
        margin-bottom: 0;
      }
      @include SP {
        padding: 29px 30px;
      }
    }
  }
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

.mod-pagination {
  @include font-size(0);
  text-align: center;
  .paging {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    a {
      display: block;
      width: 100%;
      height: 34px;
      @include font-size(0);
      border: solid 1px #E6E6E6;
      position: relative;
    }
    &.paging-prev {
      a {
        &:before {
          content: "";
          position: absolute;
          left: 40%;
          top: 38%;
          width: 8px;
          height: 8px;
          border-left: 1px solid #1B307A;
          border-top: 1px solid #1B307A;
          -moz-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
    &.paging-next {
      a {
        &:before {
          content: "";
          position: absolute;
          left: 40%;
          top: 38%;
          width: 8px;
          height: 8px;
          border-left: 1px solid #1B307A;
          border-top: 1px solid #1B307A;
          -moz-transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          transform: rotate(135deg);
        }
      }
      
    }
  }
  .paging-index {
    display: inline-block;
    vertical-align: middle;
    width: 180px;
    a {
      display: block;
      @include ffYMon;
      @include font-size(16);
      color: #1B307A;
      text-align: center;
      height: 34px;
      width: 100%;
      background: #E6E6E6;
      border-top: solid 1px #E6E6E6;
      border-bottom: solid 1px #E6E6E6;
      line-height: 34px;
    }
    
  }
}

// CUSTOM

.leftCol {
  width:70%;
  float:left;
}
.rightCol {
  width:30%;
  float:right;
}

.cmsContent {
  -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    word-wrap: break-word;

  ul {
    &[style*="list-style-type: circle;"] {
      li {
        list-style-type: circle;
      }
    }
    &[style*="list-style-type: square;"] {
      li {
        list-style-type: square;
      }
    }
    li {
      list-style: disc;
    }
  }
  ol {
    &[style*="list-style-type: lower-roman;"] {
      li {
        list-style-type: lower-roman;
      }
    }
    &[style*="list-style-type: lower-alpha;"] {
      li {
        list-style-type: lower-alpha;
      }
    }
    &[style*="list-style-type: lower-greek;"] {
      li {
        list-style-type: lower-greek;
      }
    }
    &[style*="list-style-type: upper-alpha;"] {
      li {
        list-style-type: upper-alpha;
      }
    }
    &[style*="list-style-type: upper-roman;"] {
      li {
        list-style-type: upper-roman;
      }
    }
    li {
      list-style-type: decimal;
    }
  }
  iframe {max-width: 100%!important;}
}

.pagingDt {
  position:relative;
  width:80%;
  margin:95px auto;
  font-size:14px;
}
.pagingDt a {
  text-decoration:underline;
}
.pagingDt .prev {
  background:url(../../img/blog/ico_prev.png) no-repeat left center;
  width:145px;
  float:left;
  padding-left:20px;
}
.pagingDt .next {
  background:url(../../img/blog/ico_next.png) no-repeat right center;
  width:145px;
  float:right;
  padding-right:20px;
}
.pagingDt .list {
  position:absolute;
  left:50%;
  top:50%;
  margin-left:-54px;
  margin-top:-9px;
}
/*-----------------------------------------------------------
PAGE BOOT
------------------------------------------------------------*/
/* WordPress */

.wp-pagenavi {
  margin-top: 60px;
  clear: both;
  @include ffYMon;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  @include SP {
    margin-top: 40px;
  }
  a, span {
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: #1B307A;
    margin: 0!important;
    padding: 0!important;
    border: 1px solid #E6E6E6!important;
    border-left: 0!important;
    display: inline-block;
    vertical-align: top;
  }
  a {
    &:first-child {
      border-left: 1px solid #E6E6E6!important;
    }
  }
  .first {
    width: 60px;
    @include font-size(0);
    position: relative;
    &:before {
      position: absolute;
      content: '';
      background:url($img_url+'common/icon/ico_first.svg') no-repeat left center;
      background-size: 100% 100%;
      width: 12px;
      height: 10px;
      left: 50%;
      margin-left: -6px;
      top: 50%;
      margin-top: -5px;
    }
  }
  .last {
    width: 60px;
    @include font-size(0);
    position: relative;
    &:before {
      position: absolute;
      content: '';
      background:url($img_url+'common/icon/ico_last.svg') no-repeat left center;
      background-size: 100% 100%;
      width: 12px;
      height: 10px;
      left: 50%;
      margin-left: -6px;
      top: 50%;
      margin-top: -5px;
    }
  }
  .current {
    border: 1px solid #E6E6E6!important;
    background: #E6E6E6;
    font-weight: 500!important;
  }
}
