@charset 'UTF-8';

.js-image {
  display: block;
  background: #fff center center no-repeat;
  background-size: cover;
  overflow: hidden;
  backface-visibility: hidden;
  img {
    position: absolute;
    left: -9999px;
    backface-visibility: hidden;
  }
}

.thumb-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  img {
    position: absolute;
    left: -9999px;
  }
  &:after {
    position: absolute;content: "";
    left: 0;right: 0;bottom: 0; top: 0;
  }
}

.comming {
  text-align: center;
  padding: 20px 0;
  width: 100%;
  @include font(15,32,50,500);
  @include SP {
    padding: 20px 0 0 0;
  }
}

#wrap {
  overflow: hidden;
  position: relative;
}

.cm-txt {
  @include font(15,32.5,50);
  p {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include SP {
    @include font(15,28);
    p {
      margin-bottom: 28px;
    }
  }
}
.cm-txt-hoshi {
  font-size: 0; letter-spacing: 0;
  .hoshi {
    display: inline-block;vertical-align: top;
    width: 17px;
    @include font(12,24,50);
    @include SP {
      width: 12px;
      @include font(12,22.5);
    }
  }
  .txt {
    display: inline-block;vertical-align: top;
    max-width: calc(100% - 17px);
    @include font(12,24,50);
    @include SP {
      max-width: calc(100% - 12px);
      @include font(12,22.5);
    }
  }
}

.pc-block-flex {
  @include PC {
    @include flexbox;
    @include flex-wrap(wrap);
  }
}
.c-block-flex {
  @include flexbox;
  @include flex-wrap(wrap);
}
.page-contents {
  padding: 82px 0 180px;
  @include SP {
    padding: 52px 0 160px;
  }
}
.breakAndr {
  @include screen(376px, 767px) {
    display: none;
  }
  @include ANDR {
    display: none;
  }
}

/*-----------------------------------------------------------
BACKGROUND
-------------------------------------------------------------*/
.bggradient1 {
  @include bggradient1;
}
.blue01 {
  color: $blue01;
}
/*-----------------------------------------------------------
TITLE
-------------------------------------------------------------*/
.cm-ttl01 {
  position: relative;
  padding-bottom: 20px;
  @include PC {
    .l-cn & {
      padding-bottom: 23px;
    }
  }
  &:after {
    position: absolute; content: "";
    left: 0; bottom: 0;
    width: 30px; height: 2px;
    @include bggradient1;
  }
  .ttl-en {
    @include font(40,48,150,500);
    @include ffYMon;
    color: $blue01;
    display: block;
    @include PC {
      & + .ttl-ja {
        margin-top: 8px;
        .l-cn & {
          margin-top: 13px;
        }
      }
      @include max-screen(1024px) {
        @include font(36,42);
      }
    }
  }
  .ttl-ja {
    @include font(16,20,100,500);
    @include ffYM;
    display: block;
    .l-cn & {
      @include ffSerifSC;
    }
  }
  &.is-larger {
    padding-bottom: 18px;
    .ttl-ja {
      @include font-size(30);
    }
  }
  &.is-center {
    text-align: center;
    &:after {
      right: 0;
      margin: 0 auto;
    }
  }
  @include SP {
    padding-bottom: 14px;
    .l-cn & {
      padding-bottom: 19px;
    }
    .ttl-en {
      @include font(26,30);
      & + .ttl-ja {
        margin-top: 7px;
      }
    }
    .ttl-ja {
      @include font(14,20);
    }
    &.is-larger {
      padding-bottom: 9px;
      .ttl-ja {
        @include font-size(24);
      }
    }
  }
}
.cm-ttl02 {
  position: relative;
  @include font(30,35,150,500);
  @include ffYMon;
  color: $blue01;
  &.is-center {
    text-align: center;
  }
  @include SP {
    @include font(22,30);
  }
}
.cm-ttl03 {
  @include font(18,36,100,500);
  @include ffYM;
  border-bottom: 1px solid #B8BAC1;
  padding-bottom: 10px;
  .l-cn & {
    @include ffSerifSC;
  }
  @include SP {
    @include font(17,26);
    padding-bottom: 12px;
  }
}
.cm-ttl04 {
  @include font(17,32,100,500);
  @include ffYM;
  background: $gray01;
  color: $blue01;
  padding: 8px 20px 8px;
  .l-cn & {
    @include ffSerifSC;
    @include PC {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  @include SP {
    padding: 7px 16px 7px;
    @include font(16,26);
    .l-cn & {
      padding: 11px 15px 11px;
    }
  }
}
.cm-ttl05 {
  color: $blue01;
  @include font(15,32.5,100,600);
  @include SP {
    @include font(15,28);
  }
}
.cm-ttl06 {
  @include ffYM;
  @include font(24,54,150,500);
  .l-cn & {
    @include ffSerifSC;
    padding-bottom: 15px;
  }
  @include SP {
    @include font(18,36);
  }
}


/*-----------------------------------------------------------
BUTTON
-------------------------------------------------------------*/
.cm-btn {
  max-width: 320px;
  width: 100%;
  display: block;
  margin: 0 auto;
  text-align: center;
  @include font(15,30,50);
  @include ffYM;
  color: $blue01;
  border: 1px solid $blue01;
  background: #fff;
  padding: 9px 30px;
  min-height: 50px;
  position: relative;
  z-index: 1;
  &:link, &:visited, &:active {
    color: $blue01;
  }
  .l-cn & {
    @include ffSerifSC;
  }
  @include SP {
    max-width: 265px;
    @include font(14);
    min-height: 44px;
    padding: 7px 30px;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-top: 1px solid $blue01;
    border-right: 1px solid $blue01;
    @include transform_c(rotate(45deg));
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 20px;
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background: rgb(29,83,195);
    background: linear-gradient(45deg, rgba(29,83,195,1) 0%, rgba(171,197,232,1) 100%);
    @include transition_c(opacity .4s);
    opacity: 0;
  }
  &.new-tab {
    padding-left: 34px;
    padding-right: 34px;
    &:before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 0;
      @include transform_c(rotate(0));
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 20px;
      background: url($img_url+'common/icon/ico_blank.svg') no-repeat center center;
      background-size: 100% 100%;
    }
    @include HOVER {
      &:hover {
        &:before {
          background-image: url($img_url+'common/icon/ico_blank_wh.svg');
        }
      }
    }
    @include SP {
      padding-left: 24px;
      padding-right: 24px;
      &:before {
        width: 8px;
        height: 8px;
        right: 13px;
        margin-top: -4px;
      }
    }
  }
  &.left-pc {
    @include  PC {
      margin-left: 0;
    }
  }
  &.left {
    margin-left: 0;
  }
  @include HOVER {
    &:hover {
      color: #fff;
      // border-color: transparent;
      border: 0;
      opacity: 1;
      &:after {
        opacity: 1;
      }
      &:before {
        border-top-color: #fff;
        border-right-color: #fff;
      }
    }
  }
}
.cm-btn-anchor {
  max-width: 320px;
  width: 100%;
  display: block;
  margin: 0 auto;
  text-align: center;
  @include font(15,30,50,500);
  @include ffYM;
  color: $blue01;
  padding: 10px 22px 10px;
  position: relative;
  z-index: 1;
  &:link, &:visited, &:active {
    color: $blue01;
  }
  .l-cn & {
    @include ffSerifSC;
  }
  @include SP {
    max-width: 100%;
    padding: 0 2px 3px 0;
    text-align: left;
  }
  .txt {
    position: relative;
    z-index: 29;
    display: block;
    &:before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-top: 1px solid $blue01;
      border-right: 1px solid $blue01;
      @include transform_c(rotate(135deg));
      position: absolute;
      top: 50%;
      margin-top: -4px;
      right: 0;
      @include add_prefix(transition, 0.3s ease all);
      @include SP {
        margin-top: -4px;
        right: 0px;
      }
    }
  }
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(29,83,195);
    background: linear-gradient(45deg, rgba(29,83,195,1) 0%, rgba(171,197,232,1) 100%);
    @include transition_c(opacity .4s);
    opacity: 1;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0; right: 0;
    bottom: 0; top: 0;
    background: rgb(29,83,195);
    background: linear-gradient(45deg, rgba(29,83,195,1) 0%, rgba(171,197,232,1) 100%);
    @include transition_c(opacity .4s);
    opacity: 0;
  }
  @include HOVER {
    &:hover {
      color: #fff;
      border-color: transparent;
      opacity: 1;
      &:before {
        opacity: 1;
        height: 100%;
      }
      .txt {
        &:before {
          border-top-color: #fff;
          border-right-color: #fff;
        }
      }
    }
  }
}
/*-----------------------------------------------------------
COMMON SUBPAGE
-------------------------------------------------------------*/
// breadcrumb
.breadcrumb {
  padding: 16px 0;
  .l-cn & {
    padding: 19px 0;
    ul {
      li {
        &:before {
          top: 40%;
        }
      }
    }
  }
  .wcm {
    @include PC {
      max-width: 100%;
      padding: 0 50px;
    }
  }
  ul {
    color: $color_body;
    line-height: 1.4;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    li {
      display: inline;
      @include font(14,18,0,400);
      position: relative;
      padding-right: 20px;
      color: $color_body;
      &:before {
        content: "";
        position: absolute;
        right: 6px;
        top: 30%;
        width: 6px;
        height: 6px;
        border-left: 1px solid $color_body;
        border-top: 1px solid $color_body;
        @include transform(rotate(135deg));
      }
      &:last-child {
        padding-right: 0;
        &:before {
          display: none;
          background: none;
        }
      }
    }
    a {
      color: $color_body;
      display: inline-block;
      text-decoration: none;
      @include HOVER {
        &:hover {
          text-decoration: underline;
          opacity: 1;
          color: $blue01;
        }
      }
    }
  }
}

// keyvisual
.keyvisual {
  position: relative;
  overflow: hidden;
  //height: 540px;
  .keyvisual-bg {
    height: 100%;
    .js-image {
      background-position: center top;
      @include PC {
        @include aspect-ratio(2560, 1080);
      }
    }
  }
  .wcm {
    position: absolute;
    bottom: 49px;
    left: 0; right: 0;
    @include PC {
      max-width: 100%;
      padding: 0 50px;
    }
  }
  .keyvisual-ttl {
    color: #fff;
    text-align: left;
    .ttl-ja {
      @include font(40,45,200,500);
      @include ffYM;
      display: block;
    }
    .ttl-cn {
      @include font(40,45,200,500);
      @include ffSerifSC;
      display: block;
    }
    .ttl-en {
      @include font(16,20,200,500);
      @include ffYMon;
      display: block;
      margin-top: 17px;
      .l-cn & {
        margin-top: 26px;
      }
    }
  }
  @include SP {
    //height: 300px;
    .wcm {
      bottom: 24px;
    }
    .keyvisual-bg {
      .js-image {
        @include aspect-ratio(750, 600);
      }
    }
    .keyvisual-ttl {
      .ttl-ja {
        @include font(24,34);
      }
      .ttl-cn {
        @include font(24,34);
      }
      .ttl-en {
        @include font(11,15);
        margin-top: 9px;
        .l-cn & {
          margin-top: 13px;
        }
      }
    }
  }
}

.cm-table01 {
  dl {
    padding: 18px 30px;
    &:nth-child(odd) {
      background: $gray01;
    }
    dt {
      @include font(16,28,100,500);
      @include ffYM;
      .l-cn & {
        @include ffSerifSC;
      }
    }
    dd {
      @include font(14,28,50);
      p {
        + p {
          margin-top: 17px;
          &.lineh01 {
            margin-top: 0;
          }
        }
      }
      .smaller01 {
        display: inline-block;
        margin-top: 5px;
        @include font(12,20);
      }
    }
    @include PC {
      @include flexbox;
      @include flex-wrap(wrap);
      dt {
        width: 140px;
        padding: 0 10px 0 0;
      }
      dd {
        width: calc(100% - 170px);
      }
    }
  }
  @include SP {
    dl {
      padding: 14px 20px;
      dt {
        @include font(15,28);
        margin-bottom: 5px;
      }
      dd {
        @include font(14,24.5,50);
        p {
          + p {
            margin-top: 5px;
            &.lineh01 {
              margin-top: 6px;
            }
          }
        }
        .smaller01 {
          margin-top: 6px;
          display: block;
        }
      }
    }
    &.same-sp {
      dl {
        @include flexbox;
        @include flex-wrap(wrap);
        dt {
          width: 32%;
          margin-bottom: 0;
        }
        dd {
          width: 68%;
          padding-top: 2px;
        }
      }
    }
  }
}

.cm-table02 {
  border: 1px solid $gray02;
  dl {
    border-bottom: 1px solid $gray02;
    &:last-child {
      border-bottom: 0;
    }
    dt {
      @include font(15,28,50,500);
      background: $gray01;
      @include ffYM;
      padding: 14px 30px 13px;
      .l-cn & {
        @include ffSerifSC;
      }
      @include PC {
        .l-cn & {
          padding: 16px 30px 16px;
        }
      }
    }
    dd {
      padding: 13px 30px 13px;
      @include font(15,28,50);
      @include PC {
        border-left: 1px solid $gray02;
        .l-cn & {
          padding: 17px 30px 17px;
        }
      }
      p {
        + p {
          margin-top: 17px;
          &.lineh01 {
            margin-top: 0;
          }
        }
      }
    }
    @include PC {
      @include flexbox;
      @include flex-wrap(wrap);
      dt {
        width: 26%;
      }
      dd {
        width: 74%;
      }
    }
  }
  @include SP {
    dl {
      dt {
        border-bottom: 1px solid $gray02;
        @include font(14,24);
        padding: 7px 19px 8px;
      }
      dd {
        padding: 11px 19px 11px;
        @include font(14,24);
        p {
          + p {
            margin-top: 5px;
            &.lineh01 {
              margin-top: 6px;
            }
          }
        }
        .smaller01 {
          margin-top: 6px;
          display: block;
        }
      }
    }
  }
}

.cm-table03 {
  dl {
    padding: 17px 31px;
    @include flexbox;
    @include flex-wrap(wrap);
    @include align-items(center);
    &:nth-child(odd) {
      background: $gray01;
    }
    dt {
      @include font(18,32,100,500);
      @include ffYM;
      color: $blue01;
      width: 88px;
      padding: 0 10px 0 0;
      .l-cn & {
        @include ffSerifSC;
      }
    }
    dd {
      @include font(15,32,50);
      width: calc(100% - 88px);
      padding: 0 0 0 40px;
      position: relative;
      &:before {
        position: absolute; content: "";
        left: 0; top: 7px; bottom: 7px;
        width: 1px; background: $blue01;
      }
      p {
        + p {
          margin-top: 17px;
        }
      }
    }
  }
  @include SP {
    dl {
      padding: 15px 16px 15px 20px;
      dt {
        width: 80px;
        padding: 0 5px 0 0;
      }
      dd {
        width: calc(100% - 80px);
        @include font(14,24.5,50);
        padding: 0 0 0 21px;
        &:before {
          top: 5px; bottom: 5px;
        }
          p {
            + p {
              margin-top: 5px;
            }
          }
      }
    }
  }
}

.cm-quest {
  @include ffYM;
  .l-cn & {
     @include ffSerifSC;
  }
  li {
    @include font(15,28,50,500);
    padding: 17px 20px;
    background: #F4F4F4;
    & + li {
      margin-top: 10px;
    }
    .txt {
      display: block;
      padding: 10px 0 10px 70px;
      position: relative;
      &:before {
        position: absolute;
        content: "Q";
        top: 50%;
        left: 0;
        width: 50px;
        height: 50px;
        color: $blue01;
        background: #fff;
        border-radius: 100%;
        @include ffYMon;
        @include font-size(24);
        @include letter-spacing(50);
        text-align: center;
        line-height: 50px;
        margin-top: -25px;
        padding-left: 2px;
      }
    }
    @include SP {
      padding: 0 20px;
      @include font(15,24);
      .txt {
        padding: 16px 0 16px 50px;
        &:before {
          width: 40px;
          height: 40px;
          line-height: 40px;
          @include font-size(18);
          margin-top: -20px;
          padding-left: 0;
        }
      }
    }
  }
}

.cm-checkmark {
  @include ffYM;
  .l-cn & {
    @include ffSerifSC;
  }
  li {
    @include font(15,28,50,500);
    padding: 19px 0 17px 36px;
    background: url($img_url+'common/icon/ico_check_blue.svg') left center/24px 24px no-repeat;
    border-bottom: 1px solid #E6E6E6;
    .windows & {
      padding-top: 18px;
      padding-bottom: 18px;
    }
    @include SP {
      background-position: left top 14px;
      padding: 14px 0 12px 34px;
      @include font(15,24);
    }
  }
}
.cm-style-list01 {
  li {
    position: relative;
    padding-left: 15px;
    @include font(15,32.5,50);
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      position: absolute; content: "";
      left: 0; top: 16px;
      height: 1px; width: 10px;
      background: #B8BAC1;
    }
  }
  @include SP {
    li {
      padding-left: 15px;
      @include font(15,28,50);
      margin-bottom: 11px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        top: 14px;
      }
    }
  }
}

.cm-box-white01 {
  background: #fff;
  padding: 33px 60px 30px;
  margin-bottom: 20px;
  .cm-ttl03 {
    margin-bottom: 31px;
    @include PC {
      .l-cn & {
        margin-bottom: 35px;
      }
    }
  }
  .cm-ttl04 {
    margin-bottom: 40px;
  }
  .cm-ttl05 {
    margin-bottom: 13px;
    @include PC {
      .l-cn & {
        margin-bottom: 19px;
      }
    }
  }
  .box {
    margin-top: 40px;
    .cm-ttl04 {
      margin-bottom: 32px;
      &.nxt-img {
        margin-bottom: 40px;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .img02 {
    margin-bottom: 31px;
  }
  .cm-txt {
    &:last-child {
      margin-bottom: 0;
    }
    + .cm-ttl04 {
      margin-top: 40px;
    }
    + .cm-ttl05 {
      margin-top: 23px;
      @include PC {
        .l-cn & {
          margin-top: 29px;
        }
      }
    }
  }
  .cm-btn {
    margin-top: 21px;
  }
  .pc-block-flex {
    @include PC {
      @include justify-content(space-between);
      &.img-right {
        .img {
          order: 2;
        }
        .info {
          order: 1;
        }
      }
      .img {
        width: 44.319%;
        padding-top: 8px;
      }
      .info {
        width: 50%;
      }
    }
  }

  @include SP {
    padding: 36px 30px 32px;
    .l-cn & {
      padding-top: 41px;
    }
    .cm-ttl03 {
      margin-bottom: 33px;
    }
    .cm-ttl04 {
      margin-bottom: 24px;
      &.nxt-img, &.nxt-img-sp {
        margin-bottom: 30px;
      }
    }
    .cm-ttl05 {
      margin-bottom: 9px;
      .l-cn & {
        margin-bottom: 15px;
      }
    }
    .box {
      margin-top: 33px;
      .cm-ttl04 {
        margin-bottom: 24px;
        &.nxt-img, &.nxt-img-sp {
          margin-bottom: 30px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .img {
      img {
        display: block;
        width: 100%;
      }
    }
    .img02 {
      margin-bottom: 24px;
      img {
        display: block;
        width: 100%;
      }
    }
    .cm-txt {
      &:last-child {
        margin-bottom: 0;
      }
      + .cm-ttl04 {
        margin-top: 40px;
      }
      + .cm-ttl05 {
        margin-top: 19px;
        line-height: 1.6;
        .l-cn & {
          margin-top: 26px;
        }
      }
    }
    .cm-btn {
      margin-top: 23px;
    }
    .pc-block-flex {
      .img {
        margin-bottom: 23px;
      }
    }
  }
}

.cm-list-service {
  @include flexbox();
  @include flex-wrap(wrap);
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 10px;
  .item {
    display: block;
    width: calc(25% - 20px);
    margin: 0 10px 45px;
    @include HOVER {
      .pic {
        &:before, &:after {
          position: absolute;
          top: 0;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s;
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          z-index: 1;
          background: rgb(29,83,195);
          background: -moz-linear-gradient(45deg, rgba(29,83,195,1) 5%, rgba(171,197,232,1) 95%);
          background: -webkit-linear-gradient(45deg, rgba(29,83,195,1) 5%, rgba(171,197,232,1) 95%);
          background: linear-gradient(45deg, rgba(29,83,195,1) 5%, rgba(171,197,232,1) 95%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d53c3",endColorstr="#abc5e8",GradientType=1);
        }
        &:after {
          content: "VIEW MORE";
          white-space: pre-wrap;
          word-break: break-all;
          left: 0;
          bottom: 0;
          right: 0;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          text-align: center;
          color: #fff;
          @include ffYMon;
          @include font-size(20);
          font-weight: 500;
          @include letter-spacing(150);
          z-index: 2;
          margin-left: 2px;
        }
      }
      &:hover {
        opacity: 1;
        .pic {
          &:before,
          &:after {
            opacity: 1;
            visibility: visible;
          }
          &:before {
            opacity: 0.8;
          }
        }
        .icn-arr {
          background: rgb(171,197,232);
          background: -moz-linear-gradient(55deg, rgba(171,197,232,1) 0%, rgba(29,83,195,1) 100%);
          background: -webkit-linear-gradient(55deg, rgba(171,197,232,1) 0%, rgba(29,83,195,1) 100%);
          background: linear-gradient(55deg, rgba(171,197,232,1) 0%, rgba(29,83,195,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#abc5e8",endColorstr="#1d53c3",GradientType=1);
        }
      }
    }
  }
  .ttl {
    font-weight: 500;
    color: $blue01;
    @include font-size(18);
    @include letter-spacing(100);
    text-align: center;
    @include screen(768px, 992px) {
      @include font-size(15);
    }
  }
  .pic {
    position: relative;
    overflow: hidden;
    margin-bottom: 19px;
  }
  .icn-arr {
    width: 50px;
    height: 36px;
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    background: rgb(29,83,195);
    background: -moz-linear-gradient(55deg, rgba(29,83,195,1) 0%, rgba(171,197,232,1) 100%);
    background: -webkit-linear-gradient(55deg, rgba(29,83,195,1) 0%, rgba(171,197,232,1) 100%);
    background: linear-gradient(55deg, rgba(29,83,195,1) 0%, rgba(171,197,232,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d53c3",endColorstr="#abc5e8",GradientType=1);
    transition: background 0.3s;
    &:before {
      position: absolute;
      content: "";
      display: inline-block;
      width: 9px;
      height: 9px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      @include transform_c(rotate(45deg));
      top: 50%;
      right: 50%;
      margin: -4px -2px 0 0;
    }
  }
  @include SP {
    padding: 0 25px;
    .item {
      width: 49.2345%;
      margin: 0 1.4% 16px 0;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    .ttl {
      @include font-size(14);
      @include ANDR {
        @include font-size(13);
      }
    }
    .pic {
      margin-bottom: 10px;
      img {
        display: block;
        width: 100%;
      }
    }
    .icn-arr {
      width: 40px;
      height: 28px;
      &:before {
        width: 7px;
        height: 7px;
      }
    }
  }
}

// SECTION CONTACT

.section-contact {
  margin-bottom: 70px;
  @include SP {
    margin-bottom: 10px;
  }
  .content-top {
    padding: 50px;
    background: url($img_url + 'common/f_bg01.jpg') center center no-repeat;
    background-size: cover;
    @include SP {
      background-image: url($img_url + 'common/f_bg01_sp.jpg');
      padding: 25px;
      .l-en & {
        background-image: url($img_url + 'common/f_bg01_sp_en.jpg');
      }
      .l-cn & {
        background-image: url($img_url + 'common/f_bg01_sp_cn.jpg');
      }
    }
    .detail {
      max-width: 1180px;
      height: 300px;
      margin: 0 auto;
      background: #fff;
      position: relative;
      overflow: hidden;
      @include SP {
        height: auto;
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        &:hover {
          opacity: 1;
          &:before {
            background-image: url($img_url + 'common/icon/ico_circle_arr_gradient.svg');
          }
        }
        &:before {
          position: absolute;
          content: '';
          background: url($img_url + 'common/icon/ico_circle_arr.svg') center center no-repeat;
          background-size: 100% 100%;
          width: 50px;
          height: 50px;
          top: 50%;
          margin-top: -25px;
          right: 40px;
          @include max-screen(1120px) {
            right: 20px;
          }
          @include SP {
            right: 30px;
          }
          .l-cn & {
            top: auto;
            right: auto;
            bottom: 59px;
            left: 50%;
            margin-left: -23px;
            @include SP {
              bottom: auto;
              left: auto;
              right: 30px;
              top: 50%;
              margin-top: -25px;
            }
          }
          .l-en & {
            top: auto;
            right: auto;
            bottom: 69px;
            left: 50%;
            margin-left: -26px;
            @include SP {
              bottom: auto;
              left: auto;
              right: 30px;
              top: 50%;
              margin-top: -25px;
            }
          }
        }
      }
      .detail-box {
        position: absolute;
        left: 40px;
        top: 55%;
        transform: translate(0, -50%);
        @include max-screen(1120px) {
          left: 20px;
        }
        @include SP {
          position: static;
          width: 100%;
          transform: unset;
        }
        .l-en &,.l-cn & {
          left: 0;
          width: 100%;
          text-align: center;
          transform: translate(0);
          @include SP {
            text-align: left;
          }
          .en {
            @include font-size(40);
            letter-spacing: 6px;
            margin-bottom: 23px;
            @include max-screen(1120px) {
              @include font-size(25);
              letter-spacing: 3px;
            }
            @include SP {
              @include font-size(22);
              margin-bottom: 10px;
            }
          }
          .ja {
            @include font-size(16);
          }
        }
        .l-en & {
          top: 39%;
          .en {
            @include SP {
              margin-bottom: 0;
            }
          }
        }
        .l-cn & {
          top: 28%;
        }

        .en {
          color: #1B307A;
          @include ffYMon;
          @include font-size(30);
          line-height: 20px;
          margin-bottom: 16px;
          font-weight: 500;
          letter-spacing: 4.5px;
          @include max-screen(1120px) {
            @include font-size(20);
            letter-spacing: 3px;
          }
          @include SP {
            @include font-size(22);
            line-height: 26px;
            letter-spacing: 3px;
            margin-bottom: 10px;
          }
        }
        .ja {
          @include ffYM;
          @include font-size(16);
          line-height: 20px;
          color: #15181F;
          letter-spacing: 1.5px;
          .l-cn & {
            @include ffSerifSC;
          }
        }
      }
      .detail-left {
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        left: 0;
        border-right: solid 1px #E6E6E6;
        @include PC {
          .l-en &,.l-cn & {
            transition: all 0.4s;
            &:hover {
              width: 100%;
            }
          }
        }
        @include SP {
          position: static;
          width: 100%;
          border-right: 0;
        }
        .detail-left-top {
          position: absolute;
          top: 0;
          left: 0;
          height: 50%;
          width: 100%;
          transition: all 0.3s;
          border-bottom: 1px solid #E6E6E6;
          background: #fff;
          @include PC {
            .l-en &,.l-cn & {
              height: 100%;
            }
            &:hover {
              height: 100%;
              z-index: 3;
              border-bottom: 0;
              + .detail-left-bottom {
                height: 0;
                .detail-box {
                  display: none;
                }
              }
            }
          }
          @include SP {
            position: static;
            height: auto;
            width: 100%;
            border-bottom: 0;
            border-bottom: 1px solid #E6E6E6;
            a {
              padding: 25px 30px;
              .l-cn & {
                padding: 32px 30px;
              }
              .l-en & {
                padding: 43px 30px;
              }
            }
          }
        }
        .detail-left-bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 50%;
          width: 100%;
          transition: all 0.3s;
          background: #fff;
          @include PC {
            &:hover {
              height: 100%;
              z-index: 2;
            }
          }
          @include SP {
            position: static;
            height: auto;
            width: 100%;
            border-bottom: 0;
            padding: 0;
            border-bottom: 1px solid #E6E6E6;
            a {
              padding: 25px 30px;
              .l-cn & {
                padding: 32px 30px;
              }
              .l-en & {
                padding: 37px 30px;
              }
            }
          }
        }
      }
      .detail-right {
        position: absolute;
        height: 100%;
        width: 50%;
        background: #fff;
        top: 0;
        right: 0;
        transition: all 0.4s;
        text-align: center;
        @include PC {
          &:hover {
            width: 100%;
          }
        }
        @include SP {
          position: static;
          height: auto;
          width: 100%;
          border-bottom: 0;
          border-bottom: 1px solid #E6E6E6;
          a {
            padding: 58px 30px;
            .l-cn & {
              padding: 32px 30px;
            }
            .l-en & {
              padding: 41px 30px;
            }
          }
        }
        a {
          &:before {
            top: auto;
            right: auto;
            bottom: 55px;
            left: 50%;
            margin-left: -18px;
            .l-cn & {
              @include PC {
                bottom: 59px;
                margin-left: -23px;
              }
            }
            .l-en & {
              @include PC {
                bottom: 69px;
                margin-left: -26px;
              }
            }
            @include SP {
              bottom: auto;
              left: auto;
              right: 30px;
              top: 50%;
              margin-top: -25px;
            }
          }
        }
        .detail-box {
          top: 42%;
          left: 0;
          width: 100%;
          text-align: center;
          .en {
            @include font-size(40);
            letter-spacing: 6px;
            margin-bottom: 23px;
            @include max-screen(1120px) {
              @include font-size(25);
              letter-spacing: 3px;
            }
            @include SP {
              @include font-size(22);
              margin-bottom: 10px;
            }
          }
          .l-en & {
            @include PC {
              top: 39%;
            }
            @include SP {
              .en {
                margin-bottom: 0;
              }
            }
          }
          .l-cn & {
            @include PC {
              top: 28%;
            }
          }
          @include SP {
            text-align: left;
          }

          .ja {
            @include font-size(16);
          }
        }
      }
    }
  }
  .content-mid {
    padding: 60px 0;
    text-align: center;
    border-bottom: 1px solid #E6E6E6;
    margin-bottom: 62px;
    .l-cn & {
      @include PC {
        margin-bottom: 66px;
      }
    }
    @include SP {
      padding: 40px 43px;
      margin-bottom: 0;
    }
    img {
      max-width: 412px;
      width: 100%;
    }
  }
  .content-bottom {
    padding: 0 20px;
    @include SP {
      padding: 0 25px;
    }
    .detail {
      max-width: 1120px;
      margin: 0 auto;
      .cm-btn {
        .l-en & {
          @include PC {
            @include font(15,28,25,500);
          }
        }
      }
      .detail-left {
        float: left;
        width: 50%;
        padding-right: 80px;
        border-right: solid 1px #E6E6E6;
        @include max-screen(1100px) {
          padding-right: 40px;
        }
        @include SP {
          float: none;
          width: 100%;
          padding: 30px 0 40px;
          border-right: 0;
          border-bottom: solid 1px #E6E6E6;
          .l-en &,.l-cn & {
            padding: 35px 0 43px;
          }
        }
      }
      .detail-right {
        float: right;
        width: 50%;
        padding-left: 80px;
        @include max-screen(1100px) {
          padding-left: 40px;
        }
        @include SP {
          float: none;
          width: 100%;
          padding: 30px 0;
        }
      }
      .head {
        @include font-size(16);
        font-weight: 500;
        line-height: 32px;
        color: #1B307A;
        margin-bottom: 15px;
        letter-spacing: 0.8px;
        .l-cn & {
          @include ffSerifSC;
          margin-bottom: 19px;
        }
        .l-en & {
          @include ffYMon;
          letter-spacing: 0.025em;
          margin-bottom: 22px;
        }
        @include SP {
          margin-bottom: 10px;
          .l-cn & {
            margin-bottom: 12px;
          }
          .l-en & {
            margin-bottom: 12px;
          }
        }
      }
      .address {
        @include font-size(14);
        line-height: 32px;
        padding-left: 23px;
        position: relative;
        margin-bottom: 33px;
        letter-spacing: 0.7px;
        .l-en & {
          @include PC {
            @include font(14,22,25);
            margin-bottom: 38px;
            @include min-screen(1200px) {
              padding-right: 35px;
            }
          }
        }
        @include SP {
          line-height: 24px;
          margin-bottom: 15px;
          .l-en & {
            @include font(14,18,25);
            margin-bottom: 19px;
          }
        }
        &:before {
          position: absolute;
          content: '';
          background: url($img_url + 'common/icon/ico_locate.svg') no-repeat;
          background-size: 100% 100%;
          width: 14px;
          height: 18px;
          top: 6px;
          left: 0;
          @include SP {
            top: 1px;
          }
          .l-en & {
            @include PC {
              top: 8px;
            }
          }
          .l-en & {
            @include SP {
              top: -1px;
            }
          }
        }
      }
      .map {
        margin-bottom: 26px;
        @include SP {
          margin-bottom: 16px;
          .l-en &,.l-cn & {
            margin-bottom: 20px;
          }
        }
        iframe {
          display: block;
          width: 100%;
          height: 280px;
          @include SP {
            height: 240px;
          }
        }
      }
      .phone-time {
        @include font-size(0);
        margin-bottom: 26px;
        .l-cn & {
          @include PC {
            padding-left: 15px;
            margin-bottom: 15px;
            text-align: center;
          }
        }
        .l-en & {
          @include PC {
            padding-left: 17px;
            margin-bottom: 15px;
            text-align: center;
          }
        }
        @include SP {
          text-align: center;
          margin-bottom: 22px;
          .l-en &,.l-cn & {
            margin-bottom: 15px;
          }
        }
        .phone {
          display: inline-block;
          vertical-align: middle;
          color: #1B307A;
          @include font-size(26);
          @include ffYMon;
          font-weight: 500;
          letter-spacing: 0.5px;
          padding-left: 20px;
          position: relative;
          margin-right: 15px;
          @include max-screen(1180px) {
            @include font-size(18);
            margin-right: 5px;
          }
          @include SP {
            @include font-size(26);
            display: inline-block;
            margin-right: 0;
          }
          &:before {
            position: absolute;
            content: '';
            background: url($img_url + 'common/icon/ico_tel.svg') no-repeat;
            background-size: 100% 100%;
            width: 14px;
            height: 20px;
            top: 10px;
            left: 0;
            @include max-screen(1180px) {
              top: 4px;
            }
            @include SP {
              top: 10px;
            }
          }
        }
        .time {
          display: inline-block;
          vertical-align: middle;
          @include font-size(13);
          color: #15181F;
          padding: 6px 13px;
          background: #F4F4F4;
          @include max-screen(1180px) {
            @include font-size(11);
            padding: 6px 0 6px 6px;
          }
          @include SP {
            @include font-size(12);
            display: block;
            padding: 6px 13px;
          }
        }
      }
      .cm-btn {
        max-width: 100%;
      }
    }
  }
}

