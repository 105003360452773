@charset 'UTF-8';

/*-----------------------------------------------------------
for EN Page
------------------------------------------------------------*/
.l-en {
  .keyvisual {
    .keyvisual-ttl {
      .ttl-en {
        @include PC {
          @include font-size(44);
          line-height: 1.15;
          margin-bottom: 2px;
        }
        @include SP {
          @include font-size(24);
          line-height: 1.25;
        }
      }
    }
  }
  .breadcrumb {
    padding-top: 18px;
    ul li:before {
      top: 35%;
      .android10 & {
        top: 30%;
      }
    }
  }
  .cm-btn {
    @include ffYMon;
    letter-spacing: 0;
    @include PC {
      @include font-size(14);
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @include SP {
      @include font-size(13);
      padding-bottom: 9px;
    }
  }
  .cm-btn-anchor {
    @include ffYMon;
    letter-spacing: 0;
    @include font-size(14);
    @include PC {
      padding-bottom: 11px;
    }
    @include SP {
      &:before {
        margin-top: -8px;
      }
    }
  }
  .cm-txt {
    @include letter-spacing(25);
    @include PC {
      line-height: 1.87;
      p:not(:last-child) {
        margin-bottom: 28px;
      }
    }
    @include SP {
      line-height: 1.5;
      p:not(:last-child) {
        margin-bottom: 22px;
      }
      p.msp0 {
        margin-bottom: 0;
      }
    }
  }
  .txt-note {
    @include letter-spacing(25);
    @include font-size(12);
    line-height: 1.85;
    @include SP {
      line-height: 1.5;
    }
  }
  .cm-ttl01 {
    padding-bottom: 22px;
    @include SP {
      padding-bottom: 19px;
    }
  }
  .cm-ttl03 {
    @include ffYMon;
    @include letter-spacing(25);
    line-height: 1.45;
    padding-bottom: 17px;
    @include SP {
      padding-bottom: 14px;
    }
  }
  .cm-ttl04 {
    @include ffYMon;
    @include letter-spacing(25);
    @include PC {
      line-height: 1.55;
      padding-top: 10px;
      padding-bottom: 12px;
    }
    @include SP {
      line-height: 1.375;
      padding-top: 10px;
      padding-bottom: 11px;
    }
  }
  .cm-ttl05 {
    @include letter-spacing(25);
  }
  .cm-ttl06 {
    @include ffYMon;
    @include letter-spacing(25);
    line-height: 1.5;
    @include SP {
      line-height: 1.6;
    }
  }
  .cm-list-service {
    .ttl {
      @include letter-spacing(25);
      line-height: 1.35;
    }
    @include PC {
      @include justify-content(center);
      .item {
        margin-bottom: 49px;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: calc(25.0025% - 20px);
        }
      }
      .pic {
        max-width: 295px;
      }
    }
    @include SP {
      .item {
        margin-bottom: 20px;
      }
    }
  }
  .cm-table01 {
    dl {
      dt {
        @include ffYMon;
        @include letter-spacing(25);
        @include SP {
          line-height: 1.2;
        }
      }
      dd {
        @include letter-spacing(25);
      }
    }
  }
  .cm-table02 {
    dl {
      dt {
        @include ffYMon;
        @include letter-spacing(25);
        line-height: 1.2;
        @include PC {
          padding-top: 21px;
          padding-bottom: 22px;
        }
        @include SP {
          @include flexbox();
          @include align-items(center);
          min-height: 39px;
        }
      }
      dd {
        @include PC {
          @include letter-spacing(25);
          line-height: 1.5;
          padding-top: 18px;
          padding-bottom: 19px;
        }
        @include SP {
          line-height: 1.6;
          padding-top: 14px;
          padding-bottom: 14px;
        }
      }
    }
  }
  .cm-table03 {
    dl {
      dt {
        @include ffYMon;
        @include letter-spacing(25);
        @include SP {
          @include font-size(16);
        }
      }
      dd {
        @include letter-spacing(25);
        @include PC {
          line-height: 1.87;
          padding-top: 2px;
          padding-bottom: 4px;
        }
        @include SP {
          line-height: 1.45;
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
    }
  }
  .cm-quest {
    @include ffYMon;
    li {
      @include letter-spacing(25);
      line-height: 1.5;
      @include flexbox();
      @include align-items(center);
      @include PC {
        min-height: 82px;
        padding-top: 14px;
        padding-bottom: 14px;
        .txt {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
      @include SP {
        min-height: 81px;
        .txt {
          padding-top: 18px;
          padding-bottom: 19px;
        }
      }
    }
  }
  .cm-checkmark {
    @include ffYMon;
    li {
      @include letter-spacing(25);
      line-height: 1.5;
      @include PC {
        padding-top: 24px;
        background-position: left top 21px;
      }
      @include SP {
        padding-top: 17px;
        padding-bottom: 14px;
      }
    }
  }
  .cm-style-list01 {
    li {
      @include letter-spacing(25);
      @include PC {
        line-height: 1.87;
        &:before {
          top: 15px;
        }
      }
      @include SP {
        line-height: 1.5;
        &:before {
          top: 11px;
        }
      }
    }
  }
  .cm-box-white01 {
    @include PC {
      padding-top: 39px;
      padding-bottom: 35px;
      .box {
        .cm-ttl04 {
          margin-bottom: 36px;
          &.nxt-img {
            margin-bottom: 40px;
          }
        }
      }
      .pc-block-flex .img {
        padding-top: 4px;
      }
      .img02 {
        margin-bottom: 36px;
      }
      .cm-txt+.cm-ttl05 {
        margin-top: 29px;
      }
      .cm-ttl05 {
        margin-bottom: 19px;
      }
    }
    @include SP {
      padding-top: 38px;
      padding-bottom: 38px;
      .img02 {
        margin-bottom: 29px;
      }
      .pc-block-flex .img {
        margin-bottom: 29px;
      }
      .cm-ttl05 {
        margin-bottom: 16px;
      }
      .cm-txt+.cm-ttl05 {
        margin-top: 26px;
      }
    }
  }
}
