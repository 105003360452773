@charset 'UTF-8';

.footer {
  overflow: hidden;
  background: #F4F4F4;
  padding: 20px 50px;
  @include max-screen(1000px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include SP {
    padding: 20px 25px;
  }
  .btn-backtop {
    position: fixed;
    z-index: 999;
    bottom: 20px;
    right: 20px;
    max-width: 50px;
    @include SP {
      max-width: 40px;
      bottom: 15px;
      right: 15px;
    }
  }
  .footerInner {
    max-width: 1180px;
    margin: 0 auto;
    .footer-row-top {
      padding: 45px 90px;
      border-bottom: 1px solid #E6E6E6;
      @include PC {
        @include flexbox;
        @include flex-wrap(wrap);
        // @include justify-content(space-between);
        width: 100%;
        .l-en & {
          @include justify-content(space-between);
          padding-top: 48px;
          padding-bottom: 47px;
        }
        .l-cn & {
          @include justify-content(space-between);
          padding-top: 50px;
          padding-bottom: 50px;
        }
      }
      @include max-screen(1160px) {
        padding-left: 0;
        padding-right: 0;
      }
      @include SP {
        padding: 15px 15px 5px;
        .l-en &,.l-cn & {
          padding: 20px 15px 5px;
        }
      }
      ul {
        @include PC {
          width: 21%;
          .l-cn & {
            @include min-screen(1280px) {
              width: 8%;
            }
            @include ipad {
              width: 8%;   
            }
          }
        }
        @include SP {
          float: none;
          width: 100%;
          padding: 0;
          @include font-size(0);
          margin-bottom: 20px;
          .l-cn & {
            margin-bottom: 25px;
          }
          .l-en & {
            margin-bottom: 21px;
          }
        }
        &:first-child {
          padding-left: 0;
        }
        &:nth-child(2) {
          width: 20%;
          .l-cn & {
            @include min-screen(1280px) {
              width: 7%;  
            }
            @include ipad {
              width: 7%;   
            }
          }
          @include SP {
            width: 100%;
          }
        }
        &:nth-child(3) {
          width: 16%;
          .l-cn & {
            @include min-screen(1280px) {
              width: 11%;
            }
            @include ipad {
              width: 11%;   
            }
          }
          @include SP {
            width: 100%;
          }
        }
        &:nth-child(4) {
          width: 27%;
          .l-cn & {
            @include min-screen(1280px) {
              width: 14%;
            }
            @include ipad {
              width: 14%;   
            }
          }
          @include SP {
            width: 100%;
          }
        }
        &:last-child {
          width: 15%;
          .l-cn & {
            @include min-screen(1280px) {
              width: 7%;
            }
            @include ipad {
              width: 7%;   
            }
          }
          @include SP {
            width: 100%;
          }
        }
        @include PC {
          .l-en & {
            width: auto;
          }
        }
        li {
          margin-bottom: 26px;
          &:last-child {
            margin-bottom: 0;
          }
          @include SP {
            margin-bottom: 0;
            display: inline-block;
            vertical-align: top;
            width: 55%;
            &:nth-child(2n) {
              width: 45%;
            }
            .l-cn & {
              width: 58%;
              &:nth-child(2n) {
                width: 42%;
              }
            }
          }
          a {
            letter-spacing: 0;
            @include ipad {
              @include font-size(13);
            }
            @include max-screen(950px) {
              @include font-size(12);
            }
            @include SP {
             @include font-size(13);
             letter-spacing: -0.5px;
            }
          }
        }
      }
    }
    .footer-row-mid {
      padding: 45px 90px 25px;
      border-bottom: 1px solid #E6E6E6;
      @include PC {
        .l-en & {
          padding-top: 48px;
          padding-bottom: 24px;
          border-bottom: 0;
        }
        .l-cn & {
          padding-top: 48px;
          padding-bottom: 20px;
          border-bottom: 0;
        }
      }
      @include max-screen(1160px) {
        padding-left: 0;
        padding-right: 0;
      }
      @include SP {
        padding: 25px 15px 21px;
        .l-cn & {
          padding: 27px 15px 21px;
        }
        .l-en & {
          padding: 29px 15px 15px;
        }
      }
      a.parent {
        display: block;
        margin-bottom: 21px;
        @include PC {
          .l-en & {
            margin-bottom: 24px;
          }
          .l-cn & {
            margin-bottom: 27px;
          }
        }
        @include ipad {
          @include font-size(13);
        }
        @include SP {
          margin-bottom: 8px;
          .l-en & {
            @include font(13,18,25);
            margin-bottom: 19px;
          }
          .l-cn & {
            margin-bottom: 14px;
          }
        }

      }
      ul {
        li {
          margin-bottom: 20px;
          float: left;
          width: 20%;
          .l-cn & {
            @include PC {
              margin-bottom: 27px;
            }
          }
          @include SP {
            float: none;
            width: 100%;
            margin-bottom: 5px;
            .l-cn & {
              margin-bottom: 11px;
            }
            .l-en & {
              margin-bottom: 14px;
            }
            .l-en & {
              @include font(13,16,25);
              a {
                display: inline-block;
              }
            }
          }
          &.small-pl {
            a {
              padding-left: 17px;
              &:before {
                left: 0;
              }
            }
          }
          &.small-width {
            width: 17.4%;
            .l-cn & {
              @include PC {
                width: 24.5%;
              }
            }
            @include SP {
              width: 100%;
            }
          }
          .l-en & {
            @include PC {
              margin-bottom: 24px;
              &.it01, &.it06 {
                width: 19.5%;
              }
              &.it02 {
                width: 27.5%;
              }
              &.it03 {
                width: 19%;
              }
              &.it05 {
                width: 34%;
              }
              &.it07 {
                width: 27.5%;
              }
              &.it08{
                width: 27%;
              }
            }
          }
          a {
            padding-left: 27px;
            position: relative;
            @include ipad {
              @include font-size(13);
            }
            @include max-screen(950px) {
              @include font-size(12);
            }
            .l-en & {
              @include PC {
                padding-left: 15px;
              }
            }
            @include SP {
             @include font-size(13);
             letter-spacing: 0;
             padding-left: 17px!important;
            }
            &:before {
              position: absolute;
              content: '';
              width: 10px;
              height: 1px;
              background: #B8BAC1;
              left: 9px;
              top: 8px;
              @include PC {
                .l-cn & {
                  left: 0;
                  top: 10px;
                }
                .l-en & {
                  left: 0;
                }
              }
              @include SP {
                left: 0;
                top: 5px;
                .l-en & {
                  top: 8px;
                }
                .l-cn & {
                  top: 10px;
                } 
              }
            }
          }
        }
      }
    }
    .footer-row-bottom {
      padding: 30px 90px;
      @include font-size(0);
      @include max-screen(1160px) {
        padding-left: 0;
        padding-right: 0;
      }
      @include SP {
        padding: 26px 0 28px 15px;
      }
      .content-left {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 324px);
        @include SP {
          display: block;
          width: 100%;
          margin-bottom: 14px;
        }
        ul {
          @include font-size(0);
          li {
            display: inline-block;
            vertical-align: middle;
            margin-right: 83px;
            @include SP {
              display: block;
              margin-right: 0;
              margin-bottom: 9px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            a {
              @include font-size(14);
              letter-spacing: 0.7px;
              position: relative;
              @include SP {
                @include font-size(13);
              }
              &:before {
                position: absolute;
                content: '';
                background: url($img_url + 'common/icon/ico_link.svg') no-repeat;
                background-size: 100% 100%;
                width: 10px;
                height: 10px;
                right: -18px;
                top: 2px;
                @include SP {
                  top: 1px;
                }
              }
            }
          }
        }
      }
      .content-right {
        display: inline-block;
        vertical-align: middle;
        max-width: 324px;
        width: 100%;
        @include SP {
          display: block;
          max-width: 100%;
        }
      }
    }
  }
  .copyright {
    border-top: 1px solid #E6E6E6;
    text-align: center;
    padding: 40px 15px 12px;
    margin: 0 -50px;
    @include ffYMon;
    @include font-size(12);
    color: #B8BAC1;
    text-transform: uppercase;
    letter-spacing: 1.9px;
    @include SP {
      margin: 0 -25px;
      @include font-size(10);
      line-height: 18px;
      text-align: left;
      padding: 18px 25px 0;
      letter-spacing: 1.6px;
    }
  }
}
