@charset 'UTF-8';

/*-----------------------------------------------------------
for CN Page
------------------------------------------------------------*/
.l-cn {
  .cm-list-service {
    @include PC {
      @include justify-content(center);
      .item {
        margin-bottom: 49px;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: calc(25.0025% - 20px);
        }
      }
      .pic {
        max-width: 295px;
        margin-bottom: 23px;
      }
    }
    @include SP {
      .item {
        margin-bottom: 20px;
      }
    }
  }
}
